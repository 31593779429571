.navbar-content {
	min-height: 70px;
	margin-bottom: 100px;
	position: relative;
}
.navbar .navbar-nav {
	gap: 30px;
}

.navbar-brand {
	width: 160px;
	height: 80px;
	padding: 0;
	overflow: hidden;
	padding-top: 8px;
}
.navbar-brand img {
	width: 150px;
	height: 84px;
	transform: scale(1.2);
}

.navbar-fixed-top {
	position: fixed;
	right: 0;
	left: 0;
	z-index: 1030;
	backdrop-filter: blur(16px);
	background: rgba(211, 211, 211, 0.5);
	box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.07);
}
.navbar .nav-link {
	color: rgb(0, 0, 0);
	font-family: 'Manrope', sans-serif;
	font-size: 20px;
	font-weight: 500;
	line-height: 48px;
	letter-spacing: 0%;
	text-align: left;
}
.navbar .nav-link.active {
	font-weight: 600;
}

@media screen and (max-width: 992px) {
	.navbar .navbar-nav {
		gap: 0px;
		align-items: center;
	}
	.language-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 16px;
	}
	.navbar .nav-link {
		line-height: 24px;
	}
}

.footer {
	padding: 48px 0 0;
}

.footer-desc {
	margin-top: 16px;
	color: rgb(0, 0, 0);
	font-family: 'Manrope', sans-serif;
	font-size: 13px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0%;
	text-align: left;
}

.footer-contact-title {
	color: rgb(0, 0, 0);
	font-family: 'Manrope', sans-serif;
	font-size: 24px;
	font-weight: 700;
	line-height: 40px;
	letter-spacing: 0%;
	text-align: left;
	margin-bottom: 10px;
}

.footer-contact-label {
	margin-right: 16px;
	margin-top: 16px;
	cursor: pointer;
	text-decoration: none;
}
.contact-us-text {
	color: rgb(0, 0, 0);
	font-family: 'Manrope', sans-serif;
	font-size: 13px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0%;
	text-align: left;
	margin-bottom: 10px;
}

.footer-desc-bottom {
	color: #c2c2c2;
	font-family: 'Manrope', sans-serif;
	font-size: 16px;
	font-style: oblique;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.6px;
	text-align: center;
	background: #244980;
	padding: 5px;
	margin-bottom: 0;
}
.footer-logo {
	display: flex;
	align-items: center;
	justify-content: start;
	margin-bottom: -30px;
}
.footer-logo img {
	width: 320px;
	height: 156px;
	transform: scale(1.2);
}
@media screen and (max-width: 600px) {
	.footer-logo {
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		width: 100%;
	}
	.footer-logo img {
		width: 200px;
		margin-top: 20px;
	}
	.paycom img {
		width: 80%;
	}
}
